/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useMemo, useRef, useState } from "react"
import {
  makeStyles,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  Button,
} from "@material-ui/core"
import CustomerDisplay from "components/sales-quotation/CustomerDisplay"
import EditIcon from "@material-ui/icons/Edit"
import ItemDisplay from "components/common/ItemDisplay"
import MUIDataTable from "mui-datatables"
import ChangeStatusMenu from "components/common/ChangeStatusMenu"
import { Delete, MoreVert } from "@material-ui/icons"
import { formatDate } from "utils/form-data"
import Print from "@material-ui/icons/Print"
import { simplifyDNProductDetails } from "components/invoice/utils"
import { numberFormat } from "utils/thousand-separator"
import { useReactToPrint } from "react-to-print"
import useShowSnackbar from "utils/snackbar-hooks"
import EntriesDialog from "./EntriesDialog"
import WOPrintForm from "./WOPrintForm"

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  headerTitleContainer: {
    flexGrow: "1",
    margin: "0",
  },
  headerTitle: {
    fontWeight: "bold",
  },
  buttonEdit: {
    marginLeft: "5px",
    marginRight: "5px",
  },
  buttonIcon: {
    marginLeft: "-5px",
    marginRight: "5px",
  },
  fieldLabel: {
    margin: "10px 0",
    fontSize: theme.typography.body1.fontSize,
    fontWeight: "bold",
  },
  commentNotesLabel: {
    fontWeight: "bold",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  tableTitle: {
    margin: "0",
    marginLeft: "-20px",
    fontSize: theme.typography.body1.fontSize,
  },
  sectionTitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: "bold",
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
  tableWrapper: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  commentDisplay: {
    whiteSpace: "pre-wrap",
    marginTop: "0",
    wordBreak: "break-word",
  },
  sectionTotalTable: {
    marginLeft: "auto",
    textAlign: "right",
    "& th": {
      paddingRight: "1rem",
    },
    "& tr": {
      marginBottom: "0.3rem",
    },
  },
  totalCostsTable: {
    textAlign: "right",
    "& tr > *": {
      padding: "0.4rem 0.6rem",
    },
    "& thead > tr > *": {
      verticalAlign: "top",
    },
    "& tr$totalRow > *": {
      borderTop: "1px solid black",
    },
  },
  totalRow: {},
  priceCellHead: {
    "& > *": {
      justifyContent: "flex-end",
    },
  },
  priceCellBody: {
    textAlign: "right",
  },
  purchaseGoIcon: {
    marginRight: "-0.2rem",
  },
}))

export default function WOItemDisplay({
  data,
  customerData,
  onConfirmEdit = () => {},
  showEditAndDelete = false,
  companyInfo,
  onConfirmStatusChange = () => {},
  onConfirmDelete = () => {},
  canCreateDeliveryNote = false,
  onCofirmCreateDN = () => {},
  showPrice = false,
  canCloseWarranty = false,
  onCloseWarrantyClick = () => {},
}) {
  const classes = useStyles()
  const [selections, setSelections] = useState([])
  const [showSnackbar] = useShowSnackbar()
  const generalFields = [
    { attr: "woNumber", label: "WO Number" },
    {
      atter: "woDate",
      label: "WO Date",
      display: () => <span>{formatDate(data?.woDate)}</span>,
    },
    { attr: "status", label: "Status" },
    { attr: "madeBy", label: "Made By" },
    { attr: "acknowledgeBy", label: "Acknowledge By" },
    { attr: "approvedBy", label: "Approved By" },
  ]

  const customerFields = [
    {
      attr: "",
      label: "Customer Number",
      display: () =>
        customerData ? (
          <CustomerDisplay propName="custCode" customerData={customerData} />
        ) : (
          <span style={{ color: "gray" }}>Unspecified</span>
        ),
    },
    {
      attr: "",
      label: "PO Number",
      display: () => {
        let poNumber = []
        data.estimateList.map((est) => {
          poNumber.push(est?.salesEstimate?.slsPurchaseOrder?.poNumber ?? "-")
          return 1
        })
        poNumber = Array.from(new Set(poNumber.map((number) => number)))
        return <span>{poNumber.join(" | ")}</span>
      },
    },
    {
      attr: "projectName",
      label: "Project",
      display: (val) => {
        if (val) return val
        const { estimateList = [] } = data
        const arr = []

        estimateList.forEach((item) => {
          arr.push(item.salesEstimate?.estQuot?.rfq?.projectName ?? "-")
        })
        const uniqueArr = Array.from(new Set([...arr]))
        return uniqueArr.join(", ")
      },
    },
  ]

  const title = `Work Order of “${data.woNumber}”`

  const itemDisplayProps = {
    data,
    showEditButton: false,
    showDeleteButton: false,
  }

  const tableNumberColumn = {
    name: "no",
    label: "No",
    options: {
      setCellHeaderProps: () => ({
        style: {
          width: "1px",
        },
      }),
      customBodyRenderLite: (index) => index + 1,
    },
  }

  const tableOptions = {
    elevation: 0,
    download: false,
    print: false,
    selectableRows: "multiple",
    responsive: "standard",
    selectableRowsHeader: canCreateDeliveryNote,
    selectToolbarPlacement: "none",
    isRowSelectable: (dataIndex) =>
      data.estimateList[dataIndex]?.salesEstimate?.status !== 3,
    onTableChange: (...args) => {
      const [action, tableState] = args

      if (action === "rowSelectionChange") {
        setSelections(
          tableState.selectedRows.data.map((item) => item.dataIndex)
        )
      }
    },
  }

  const columnOptions = useMemo(
    () => {
      const arr = [
        tableNumberColumn,
        {
          name: "description",
          label: "Product Name",
          options: {
            customBodyRenderLite: (index) => {
              const item = data.estimateList[index]
              const productName = item?.salesEstimate?.description ?? "-"
              return item?.description ?? productName
            },
          },
        },
        {
          name: "mouldCode",
          label: "Product Code",
        },
        {
          name: "remark",
          label: "Remark",
          options: {
            customBodyRender: (val) => (
              <div style={{ whiteSpace: "pre-line" }}>{val}</div>
            ),
          },
        },
        {
          name: "dueDateDelivery",
          label: "Due Date Delivery",
          options: {
            customBodyRender: (val) => {
              return formatDate(val)
            },
          },
        },
        {
          name: "schedules",
          label: "Schedule",
          options: {
            customBodyRenderLite: (index) => {
              const item = data.estimateList[index]
              const { schedules = [] } = item
              if (!item.schedules.length) return "No schedules available"
              return (
                <ul style={{ listStyleType: "none", paddingInlineStart: "0" }}>
                  {schedules.map((schedule, i) => (
                    <li key={`${schedule.name}${i}`}>
                      {schedule.name} : {formatDate(schedule.date)}
                    </li>
                  ))}
                </ul>
              )
            },
          },
        },
        {
          name: "mouldCodeRef",
          label: "Ref",
        },
      ]

      if (showPrice) {
        arr.splice(2, 0, {
          name: "price",
          label: "Price",
          options: {
            customBodyRenderLite: (index) => {
              const item = data.estimateList[index]

              const { totalCost } = simplifyDNProductDetails([
                { salesEstimate: item.salesEstimate },
              ])[0]

              const cost = item.salesEstimate.totalAmount || totalCost
              return numberFormat(cost - item.salesEstimate?.discount || 0)
            },
          },
        })
      }

      if (data.workOrderTypeId === 2) {
        arr.push({
          label: "Warranty Status",
          name: "warrantyStatus",
          options: {
            customBodyRenderLite: (index) => {
              const item = (data?.estimateList ?? [])[index]

              if (item.warrantyStatus?.status !== "CLOSED") {
                return (
                  <>
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>
                      Open
                    </p>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!canCloseWarranty}
                      onClick={() => {
                        onCloseWarrantyClick(item)
                      }}
                    >
                      MARK AS CLOSED
                    </Button>
                  </>
                )
              }

              return (
                <div>
                  <strong>Closed</strong>
                  <p>{item.warrantyStatus?.remark}</p>
                </div>
              )
            },
          },
        })
      }

      return arr
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  const [open, setOpen] = useState(false)

  const [approver, setApprover] = useState([])

  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const onConfirm = (items) => {
    setApprover(items)
    setTimeout(() => {
      setOpen(false)
      handlePrint()
    }, 500)
  }

  const statusChangeMenuAnchorElRef = useRef(null)
  const [openStatusChangeMenu, setOpenStatusChangeMenu] = useState(false)

  const handleCreateDNClick = () => {
    if (!selections.length) return
    const currencyIds = Array.from(
      new Set(
        selections.map((index) => {
          const curr = data.estimateList[index]
          return curr?.salesEstimate?.slsQuot?.currencyOut?.idCurr
        })
      )
    )
    if (currencyIds.length > 1) {
      showSnackbar("Quotation currency must be the same")
      return
    }
    const quotWithoutPO = []
    const quotWithPO = []

    selections.forEach((index) => {
      const curr = data.estimateList[index]
      if (!curr.salesEstimate?.slsPurchaseOrder) quotWithoutPO.push(curr)
      else quotWithPO.push(curr)
    })
    if (quotWithPO.length && quotWithoutPO.length) {
      showSnackbar("Can't create delivery note")
      return
    }
    const currency =
      data.estimateList[selections[0]].salesEstimate?.slsQuot?.currencyOut

    const productDetails = selections.map((index) => {
      const curr = data.estimateList[index]

      const { totalCost } = simplifyDNProductDetails([
        { salesEstimate: curr.salesEstimate },
      ])[0]

      let totalAmount

      if (curr.salesEstimate?.totalAmount) {
        totalAmount =
          curr.salesEstimate?.totalAmount - curr.salesEstimate?.discount
      }

      const obj = {
        description: curr.description,
        quantity: curr?.salesEstimate?.quantity,
        unit: curr?.salesEstimate?.uom?.name,
        remarks: curr?.remark,
        salesEstimateId: curr?.salesEstimateId,
        mouldCode: curr?.mouldCode,
        unitPrice: totalAmount ?? totalCost,
        poNumber: curr?.salesEstimate?.slsPurchaseOrder?.poNumber,
        slsPurchaseOrder: curr?.salesEstimate?.slsPurchaseOrder,
      }
      return obj
    })

    const params = {
      customerId: data?.customerId,
      customer: data?.customer,
      attnName: data?.customer?.pic,
      address: data?.customer?.address,
      phone: data?.customer?.phoneNumber,
      fax: data?.customer?.faxNumber,
      isTemporary: quotWithPO.length === 0,
      currency,
      productDetails,
    }
    onCofirmCreateDN(params)
  }

  return (
    <>
      <EntriesDialog
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={onConfirm}
      />
      <div className={classes.headerContainer}>
        <div className={classes.headerTitleContainer}>
          <Typography variant="h6" className={classes.headerTitle}>
            {title}
          </Typography>
        </div>

        <Tooltip title="Edit">
          <IconButton
            variant="contained"
            color="primary"
            onClick={onConfirmEdit}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {showEditAndDelete && (
          <>
            {data.progress !== "post" && (
              <Tooltip title="Delete">
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={onConfirmDelete}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
        <Tooltip title="Print">
          <IconButton
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            <Print fontSize="small" />
          </IconButton>
        </Tooltip>
        {showEditAndDelete && (
          <>
            <Tooltip title="More...">
              <IconButton
                color="primary"
                ref={statusChangeMenuAnchorElRef}
                onClick={() => setOpenStatusChangeMenu(true)}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
            <ChangeStatusMenu
              open={openStatusChangeMenu}
              itemType="Work Order"
              anchorEl={statusChangeMenuAnchorElRef.current}
              showPost
              onClose={() => setOpenStatusChangeMenu(false)}
              onConfirmStatusChange={onConfirmStatusChange}
            />
          </>
        )}

        <WOPrintForm
          ref={componentRef}
          companyInformation={companyInfo}
          approver={approver}
          data={data}
          estimateList={data.estimateList}
          customerData={customerData}
        />
      </div>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <ItemDisplay {...itemDisplayProps} attrsAndLabels={generalFields} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ItemDisplay {...itemDisplayProps} attrsAndLabels={customerFields} />
        </Grid>
      </Grid>

      <div className={classes.tableWrapper}>
        <MUIDataTable
          title={
            <div className={classes.tableTitle}>
              <strong>Work Order Detail</strong>
            </div>
          }
          data={data.estimateList}
          columns={columnOptions}
          options={tableOptions}
        />
        {canCreateDeliveryNote ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateDNClick}
            style={{ marginTop: "12px" }}
          >
            Create Delivery Note
          </Button>
        ) : null}
      </div>
    </>
  )
}
