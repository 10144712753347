/* eslint-disable react/prop-types */
import { Button, FormControlLabel, Switch } from "@material-ui/core"
import BaseDialog from "components/common/BaseDialog"
import React, { useState } from "react"

function InvoicePrintSettingDialog({ open, onClose }) {
  const [withRefQuot, setWithRefQuot] = useState(false)
  const [showPercentage, setShowPercentage] = useState(false)
  const [mergeAdditionalCost, setMergeAdditionalCost] = useState(false)
  const [showBreakdown, setShowBreakdown] = useState(false)

  return (
    <BaseDialog
      open={open}
      onClose={() => onClose({})}
      hideCancelButton
      hideConfirmButton
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <FormControlLabel
          control={
            <Switch
              checked={withRefQuot}
              onChange={(e) => setWithRefQuot(e.target.checked)}
              color="primary"
            />
          }
          label="With Ref Quot"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showPercentage}
              onChange={(e) => setShowPercentage(e.target.checked)}
              color="primary"
            />
          }
          label="With Payment Percentage"
        />

        <FormControlLabel
          control={
            <Switch
              checked={mergeAdditionalCost}
              onChange={(e) => setMergeAdditionalCost(e.target.checked)}
              color="primary"
            />
          }
          label="Merge Additional Cost"
        />

        <FormControlLabel
          control={
            <Switch
              checked={showBreakdown}
              onChange={(e) => setShowBreakdown(e.target.checked)}
              color="primary"
            />
          }
          label="Show Sales Quot Breakdown"
        />

        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onClose({
              withPaymentPercentage: showPercentage,
              withRefQuot,
              mergeAdditionalCost,
              showBreakdown,
            })
          }}
        >
          Print
        </Button>
      </div>
    </BaseDialog>
  )
}

export default InvoicePrintSettingDialog
