import {
  adjustDefaultParams,
  getDataAndCountFromResponse,
  getQueryForAllAndCount,
  handleSilentApiError,
  newDictToStringParam,
} from "./utils"
import { ENDPOINT, axios } from "./constants"
import { findSalesEstimatesActiveReduction } from "components/sales-quotation/utils"
import { orderBy } from "lodash"

const ITEM_DETAIL_QUERY = `
idSalesQuotation
customerId
slsQuotDate
slsQuotNumber
slsQuotName
destinationCustomerId
destinationCustomer {
  idCustomerDestination 
  destination
}
paymentMethod {
  idPayment
  name
  description
}
slsPurchaseOrders {
  poNumber
  poDate
}
slsQuotMasterId
slsQuotMaster {
  idSalesQuotation
  slsQuotNumber
  slsQuotName
}
warranty
top
mouldNumber
notesWo
reference
expShipDate
trialDate
leadTime
rateInId
rateOutId
rateInValue
rateOutValue
adjustRate
rateDate
comment
poNumber
poDate
woDate
woNumber
margin
status
design
machining
assembly
finishing
salesId
discount
tax
approvedBy
acknowledgedBy
checkedBy
notes
additionalCostMargin
sales {
  idUser
  name
  employeeNumber
}
additionalCosts{
  process
  quantity
  cost
}
estimateList {
  idSalesEstimate
  estQuotId
  description
  loc
  quantity
  totalAmount
  unitOfMeasurement
  reductionHistory
  handling
  sequence
  discount
  slsPurchaseOrder {
    poNumber
    poDate
  }
  uom {
    idUom
    name
  }
  details {
    slsPurchaseOrder {
      poNumber
      poDate
    }
    uom {
      idUom
      name
    }
    description
    unitPrice
    quantity
  }
  relatedWorkOrderEstimate {
    edges{
      node {
        idWorkOrderEstimate
        workOrder {
          woNumber
          progress
        }
        mouldCode
      }
    }
  }
  relatedInvoiceDetail {
    edges {
      node {
        idInvoiceDetail
        invoice {
          status
          enableRevise
        }
      }
    }
  }
  relatedDeliveryNoteDetail {
    edges {
      node {
        relatedInvoiceDetail {
          edges {
            node {
              idInvoiceDetail
              invoice {
                status
                enableRevise
              }
            }
          }
        }
      }
    }
  }
  uomId
  estQuot {
    idEstimate
    estimationNumber
    processCostHandling
    totalActualCost
    materialCostHandling
    additionalCostHandling 
    outsourcingCostHandling 
    totalCost
    rfq {
      rfqNumber
      productName
      productNo
    }  
    materialCosts {
      materialId
      dimension
      quantity      
      sequence
      unitPrice
      material {
        materialCode
        unitPrice
        dimension
        materialType {
          density
          name
        }
      }
    }
    processCosts {
      processId
      time
      cost
    }
    additionalCosts {
      cost
      quantity
      handling
    }
    outsourcingCosts {
      cost
      quantity
      handling
    }
    projectCosts {
      cost
      bomNumber
    }
  }
  
}`

export default class ApiSalesQuotation {
  static async get(inpParams = {}) {
    const params = adjustDefaultParams(inpParams)

    let query = `
    query {
      allSalesQuotationImpl {
        idSalesQuotation
        slsQuotNumber
        slsQuotName
        slsQuotDate
        status
        customerId
        sales {
          name
        }
        slsTargetCustomer {
          name
        }
        slsPurchaseOrders {
          poNumber
        }
        discount
        expShipDate
        estimateList {
          description
          relatedWorkOrderEstimate {
            edges {
              node {
                mouldCode
              }
            }
          }
          totalAmount
          estQuot{
            estimationNumber
            rfq{
              processType{
                processType
              }
            }
          }
        }
      }
    }`
    query = getQueryForAllAndCount(query, params)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)
    let val = getDataAndCountFromResponse(response)
    if (val.data.length > 0) {
      const newData = val.data.map((item) => {
        const { sales, ...otherItem } = item
        return { ...otherItem, salesName: sales.name }
      })
      const { ...otherData } = val
      val = { ...otherData, data: newData }
    }
    return val
  }

  static async getSQOutstanding(inpParams = {}) {
    const payload = adjustDefaultParams({ ...inpParams, outstanding: true })
    let query = `
      query {
        allSalesQuotationImpl {
          slsQuotNumber
          idSalesQuotation
          estimateList {
            description
            estQuot {
              estimationNumber
            }
            relatedWorkOrderEstimate {
              edges {
                node {
                  mouldCode
                  description
                  workOrder {
                    woNumber
                    workOrderType {
                      workOrderTypeName
                    }
                  }
                }
              }
            }
          }
          slsTargetCustomer {
            name
          }
        }
      }`
    query = getQueryForAllAndCount(query, payload)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)

    return getDataAndCountFromResponse(response)
  }

  static async getAllWoNumber() {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allSalesQuotationImpl {
          idSalesQuotation
          status
          woNumber
        }
      }`,
    })
    handleSilentApiError(response)

    const items = response.data.data.allSalesQuotationImpl
    const executedQuotations = items.filter((item) => item.status === "execute")
    let woNumbers = executedQuotations.map((item) => item.woNumber)
    woNumbers = woNumbers.filter((item) => item) // Filter nill values
    woNumbers = new Set(woNumbers)
    woNumbers = Array.from(woNumbers)

    return { data: woNumbers }
  }

  static async getAllPoNumber({ includeEmpty = false } = {}) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allSalesQuotationImpl {
          idSalesQuotation
          status
          poNumber
          customerId
          slsQuotNumber
          deliveryStatus
          rateOutId
          rateOutValue
          slsQuotName
          estimateList {
            description
            quantity
            handling
            reductionHistory
            quantity
            discount
            slsQuot {
              slsQuotNumber
              poNumber
              poDate
            }
            estQuot {
              processCostHandling
              materialCostHandling
              additionalCostHandling
              processCosts {
                id
                time
                cost
                processId
                process {
                  cost
                }
              }
              materialCosts {
                materialId
                dimension
                quantity      
                sequence
                unitPrice
                material {
                  materialCode
                  unitPrice
                  dimension
                  idMaterial
                  materialType {
                    density
                    name
                  }
                }
              }
              additionalCosts {
                quantity
                process
                cost
                handling
              }
            }
            idSalesEstimate
            uom {
              name
            }
            relatedWorkOrderEstimate {
              edges{
                node {
                  idWorkOrderEstimate
                  workOrder {
                    status
                    idWorkOrder
                    woNumber
                  }
                  relatedBom {
                    edges {
                      node {
                        outgoingQualityReportBomData {
                          edges {
                            node {
                              idOutgoingMaterialReport
                              finalJudgement
                            }
                          }
                        }
                      }
                    }
                  }
                  mouldCode
                }
              }
            }
          }
        }
      }`,
    })
    handleSilentApiError(response)

    const items = response.data.data.allSalesQuotationImpl
    const executedQuotations = items.filter(
      (item) =>
        item.status === "execute" &&
        (item.poNumber || includeEmpty) &&
        item.deliveryStatus !== 3
    )
    console.log(executedQuotations)
    let poNumbers = executedQuotations.map((item) => item.poNumber)
    poNumbers = poNumbers.filter((item) => item !== null) // Filter nill values
    poNumbers = new Set(poNumbers)
    poNumbers = Array.from(poNumbers)

    return { data: executedQuotations }
  }

  static async getEstimateItem(id) {
    const items = await this.getItem(id)
    return { data: items.data.estimateList }
  }

  static async getAllExecutedItem(payload = {}) {
    const params = adjustDefaultParams({ ...payload, status: "execute" })

    let query = `
    query {
      allSalesQuotationImpl {
        idSalesQuotation
        slsQuotNumber
        slsQuotName
        estimateList {
          description
        }
      }
    }`
    query = getQueryForAllAndCount(query, params)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)
    return {
      data: response.data.data.allSalesQuotationImpl,
    }
  }

  static async getItem(id) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
        query { 
          getSalesQuotationImpl (id: ${id}) {
            ${ITEM_DETAIL_QUERY}
          }
        }`,
    })
    handleSilentApiError(response)
    let {
      estimateList,
      slsPurchaseOrders,
      ...otherItem
    } = response.data.data.getSalesQuotationImpl
    slsPurchaseOrders = slsPurchaseOrders.map((po) => ({
      ...po,
      poDate: (po.poDate || "").slice(0, 10),
    }))
    estimateList = estimateList.map((item) => {
      const reduction = findSalesEstimatesActiveReduction(item)

      const activeReduction = reduction?.reduction || 0
      return {
        ...item,
        reduction: activeReduction,
        unitOfMeasurement: item?.uom?.name,
      }
    })

    estimateList = orderBy(estimateList, ["sequence"])

    const newData = { ...otherItem, estimateList, slsPurchaseOrders }

    return { data: newData }
  }

  static async getItemEstimateData(id) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
        query { 
          getSalesQuotationImpl (id: ${id}) {
            ${ITEM_DETAIL_QUERY}
          }
        }`,
    })
    handleSilentApiError(response)
    const data = response.data.data.getSalesQuotationImpl
    const items = data.estimateList

    return { data: items }
  }

  /**
   * Fetch all quotations that has been executed AND is free.
   * If includeIds is given, it will also fetch said ids.
   * @param {Array} includeIds
   */
  static async getAllExecutedQuotationsForBOM(includeIds = []) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allSalesQuotationImpl {
          idSalesQuotation
          slsQuotNumber
          slsQuotName
          woNumber
          reference
          customerId
          status
          mouldNumber
          relatedBom {
            edges {
              node {
                idBom
              }
            }
          }
          workOrder {
            woNumber
            estimateList {
              salesEstimateId
              idWorkOrderEstimate
              mouldCode
              salesEstimate {
                estQuot {
                  rfq {
                    productName
                    projectName
                    productNo
                    productTypeId
                    productType {
                      idProductType
                      productType
                      code
                    }
                  }
                }
              }
            }
          }
        }
      }`,
    })
    handleSilentApiError(response)
    const allItems = response.data.data.allSalesQuotationImpl
    const items = allItems.filter((item) => {
      const isInIncludedIds = includeIds.includes(item.idSalesQuotation)
      const isExecuted = item.status === "execute"
      const hasNoBill =
        item.relatedBom.edges.length <
        (item.workOrder?.estimateList || []).length

      // console.log(item);
      return (isExecuted && hasNoBill) || isInIncludedIds
    })

    return { data: items }
  }

  /**
   * Fetch all quotations that has been executed AND is free.
   * If includeIds is given, it will also fetch said ids.
   * @param {Array} includeIds
   */
  static async getAllExecutedQuotationsForBOMNew(includeIds = []) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allWorkOrderImpl {
          woNumber
          progress
          projectName
          estimateList {
            salesEstimateId
            idWorkOrderEstimate
            mouldCode
            description
            relatedBom {
              edges {
                node {
                  idBom
                }
              }
            }
            salesQuotation {
              idSalesQuotation
              slsQuotNumber
              slsQuotName
              woNumber
              reference
              customerId
              status
              mouldNumber
              relatedBom {
                edges {
                  node {
                    idBom
                  }
                }
              }
            }
            salesEstimate {
            description
              estQuot {
                rfq {
                  productName
                  projectName
                  productNo
                  productTypeId
                  productType {
                    idProductType
                    productType
                    code
                  }
                }
              }
            }
          }
        }
      }`,
    })
    handleSilentApiError(response)
    const allWorkOrderItems = response.data.data.allWorkOrderImpl
    let quotationItems = []

    const filteredWorkOrder = allWorkOrderItems
      .filter((item) => item.progress === "post")
      .map((item) => {
        const { estimateList } = item
        const notHaveBom = estimateList.filter((estList) => {
          const bomLength = estList.relatedBom.edges.length
          return bomLength === 0
        })
        if (item.woNumber === "R-005/KMI-WO/11/2022") {
          console.log(notHaveBom)
        }
        return {
          ...item,
          estimateList: notHaveBom,
        }
      })
      .filter((item) => item.estimateList.length)

    filteredWorkOrder.forEach((item) => {
      item.estimateList.forEach((estItem) => {
        if (estItem.salesQuotation) {
          const newObj = {
            ...estItem.salesQuotation,
            woNumber: item.woNumber,
            projectName: item.projectName,
            estimateList: item.estimateList,
          }
          if (
            !quotationItems.filter(
              (qi) =>
                qi.woNumber === item.woNumber &&
                qi.slsQuotNumber === estItem.salesQuotation.slsQuotNumber
            )[0]
          ) {
            quotationItems.push(newObj)
          }
        }
      })
    })

    return {
      data: {
        salesQuotations: quotationItems,
        allWorkOrderItems: filteredWorkOrder,
      },
    }
  }

  static async getItemForBOM(id) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        getSalesQuotationImpl (id: ${id}) {
          idSalesQuotation
          slsQuotNumber
          slsQuotName
          woNumber
          reference
          customerId
          status
          workOrder {
            woNumber
          }
        }
      }`,
    })
    handleSilentApiError(response)
    return { data: response.data.data.getSalesQuotationImpl }
  }

  static async create(payload) {
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
      mutation ($sales: SalesQuotationInput!) {
        saveSalesQuotationImpl(sales: $sales) {
          salesQuotationImpl {
            idSalesQuotation
          }
        }
    }`,
      variables: { sales: payload },
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.saveSalesQuotationImpl.salesQuotationImpl,
    }
  }

  static async delete(idSalesQuotation) {
    const response = await axios.post(ENDPOINT.DELETE, {
      query: `
      mutation {
        deleteSalesQuotationImpl(idSalesQuotation: ${idSalesQuotation}) { 
          salesQuotation { 
            idSalesQuotation 
          } 
        } 
      }`,
    })
    handleSilentApiError(response)

    return { data: response.data.data.deleteSalesQuotationImpl.salesQuotation }
  }

  static async revise(id, payload) {
    const params = newDictToStringParam({
      idSalesQuotation: id,
      ...payload,
    })
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        reviseSalesQuotationImpl(${params}) {
          salesQuotationImpl {
            ${ITEM_DETAIL_QUERY}
          }
        }
      }`,
    })
    handleSilentApiError(response)
    let {
      estimateList,
      ...otherItem
    } = response.data.data.reviseSalesQuotationImpl.salesQuotationImpl

    estimateList = estimateList.map((item) => {
      const reduction = findSalesEstimatesActiveReduction(item)

      const activeReduction = reduction?.reduction || 0
      return {
        ...item,
        reduction: activeReduction,
        unitOfMeasurement: item?.uom?.name,
      }
    })

    estimateList = orderBy(estimateList, ["sequence"])

    const newData = { ...otherItem, estimateList }

    return { data: newData }
  }

  static async update(id, payload) {
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation ($id: ID!, $sales: SalesQuotationInput!) {
        updateSalesQuotationImpl(idSalesQuotation: $id, sales: $sales) {
          salesQuotationImpl{
            ${ITEM_DETAIL_QUERY}
          }
        }
      }`,
      variables: {
        id,
        sales: payload,
      },
    })
    handleSilentApiError(response)

    let {
      estimateList,
      ...otherItem
    } = response.data.data.updateSalesQuotationImpl.salesQuotationImpl

    estimateList = estimateList.map((item) => {
      const reduction = findSalesEstimatesActiveReduction(item)

      const activeReduction = reduction?.reduction || 0
      return {
        ...item,
        reduction: activeReduction,
        unitOfMeasurement: item?.uom?.name,
      }
    })

    estimateList = orderBy(estimateList, ["sequence"])

    const newData = { ...otherItem, estimateList }

    return { data: newData }
  }

  static async updateWorkOrderData(idSalesQuotation, payload) {
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation ($id: ID!, $sales: UpdateSalesWorkOrderInput! ) {
        updateSalesWorkOrderImpl (idSalesQuotation: $id, sales: $sales) {
          salesQuotationImpl{
            ${ITEM_DETAIL_QUERY}
          }
        }
      }`,
      variables: {
        id: idSalesQuotation,
        sales: payload,
      },
    })
    handleSilentApiError(response)

    let {
      estimateList,
      ...otherItem
    } = response.data.data.updateSalesWorkOrderImpl.salesQuotationImpl

    estimateList = estimateList.map((item) => {
      const reduction = findSalesEstimatesActiveReduction(item)

      const activeReduction = reduction?.reduction || 0
      return {
        ...item,
        reduction: activeReduction,
        unitOfMeasurement: item?.uom?.name,
      }
    })

    const newData = { ...otherItem, estimateList }
    return {
      data: newData,
    }
  }

  static async updateMouldAndNotesWO(id, payload) {
    const payloadAsParam = newDictToStringParam({
      ...payload,
      idSalesQuotation: parseInt(id, 10),
    })
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        printWo (${payloadAsParam}) {
          salesQuotationImpl{
            ${ITEM_DETAIL_QUERY}
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.printWo.salesQuotationImpl,
    }
  }

  static async updateStatus(id, payload) {
    const payloadAsParam = newDictToStringParam({
      status: payload,
      idSalesQuotation: parseInt(id, 10),
    })
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        updateStatusSalesQuotationImpl (${payloadAsParam}) {
          salesQuotationImpl{
            status
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data:
        response.data.data.updateStatusSalesQuotationImpl
          .updateStatusSalesQuotation,
    }
  }

  static async getForDeliveryNote(payload = {}) {
    const params = newDictToStringParam({ ...payload, status: "execute" })
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allSalesQuotationImpl(${params}) {
          idSalesQuotation
          status
          poNumber
          customerId
          slsQuotNumber
          deliveryStatus
          rateOutId
          rateOutValue
          slsQuotName
          slsQuotMasterId
          slsPurchaseOrders {
            poNumber
            poDate
          }
          estimateList {
            description
            quantity
            handling
            reductionHistory
            quantity
            discount
            slsPurchaseOrder {
              poNumber
              poDate
            }
            totalAmount
            estQuot {
              processCostHandling
              materialCostHandling
              additionalCostHandling
              processCosts {
                id
                time
                cost
                processId
                process {
                  cost
                }
              }
              materialCosts {
                materialId
                dimension
                quantity      
                sequence
                unitPrice
                material {
                  materialCode
                  unitPrice
                  dimension
                  idMaterial
                  materialType {
                    density
                    name
                  }
                }
              }
              additionalCosts {
                quantity
                process
                cost
                handling
              }
            }
            idSalesEstimate
            uom {
              name
            }
            relatedWorkOrderEstimate {
              edges{
                node {
                  idWorkOrderEstimate
                  status
                  workOrder {
                    status
                    idWorkOrder
                    woNumber
                  }
                  relatedBom {
                    edges {
                      node {
                        outgoingQualityReportBomData {
                          edges {
                            node {
                              idOutgoingMaterialReport
                              finalJudgement
                            }
                          }
                        }
                      }
                    }
                  }
                  mouldCode
                }
              }
            }
          }
        }
      }`,
    })
    handleSilentApiError(response)

    const items = response.data.data.allSalesQuotationImpl
    const executedQuotations = items.filter(
      (item) => item.status === "execute" && item.deliveryStatus !== 3
    )

    const data = executedQuotations.filter((d) => {
      const { estimateList } = d
      const filteredEstimateList = estimateList.filter((est) => {
        const { relatedWorkOrderEstimate } = est

        return relatedWorkOrderEstimate.edges.filter(
          (edge) => ![0, 3].includes(edge.node.status)
        ).length
      })
      return filteredEstimateList.length || Boolean(d.slsQuotMasterId)
    })

    return { data }
  }

  static async getSQForInvoice(payload = {}) {
    const params = newDictToStringParam({ ...payload, status: "execute" })
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allSalesQuotationImpl (${params}) {
          idSalesQuotation
          status
          poNumber
          customerId
          slsQuotNumber
          deliveryStatus
          rateOutId
          rateOutValue
          slsQuotName
          slsPurchaseOrders {
            poNumber
            poDate
          }
          estimateList {
            description
            idSalesEstimate
            quantity
            handling
            reductionHistory
            quantity
            discount
            slsPurchaseOrder {
              poNumber
              poDate
            }
            paymentStatus
            totalAmount
            estQuot {
              totalCost
              processCostHandling
              materialCostHandling
              additionalCostHandling
              processCosts {
                id
                time
                cost
                processId
                process {
                  cost
                }
              }
              materialCosts {
                materialId
                dimension
                quantity      
                sequence
                unitPrice
                material {
                  materialCode
                  unitPrice
                  dimension
                  idMaterial
                  materialType {
                    density
                    name
                  }
                }
              }
              additionalCosts {
                quantity
                process
                cost
                handling
              }
            }
            idSalesEstimate
            uom {
              name
            }
            relatedWorkOrderEstimate {
              edges{
                node {
                  idWorkOrderEstimate
                  workOrder {
                    status
                    idWorkOrder
                    woNumber
                  }
                  relatedBom {
                    edges {
                      node {
                        outgoingQualityReportBomData {
                          edges {
                            node {
                              idOutgoingMaterialReport
                              finalJudgement
                            }
                          }
                        }
                      }
                    }
                  }
                  mouldCode
                }
              }
            }
          }
        }
      }`,
    })
    handleSilentApiError(response)
    return {
      data: response.data.data.allSalesQuotationImpl,
    }
  }
  static async getPOForInvoice(payload = {}) {
    const response = await this.getSQForInvoice(payload)

    const arr = []

    const { data } = response

    for (let i = 0; i < data.length; i++) {
      const curr = data[i]
      for (let j = 0; j < curr.slsPurchaseOrders.length; j++) {
        const currPo = curr.slsPurchaseOrders[j]
        const estimateList = curr.estimateList.filter(
          (est) => est.slsPurchaseOrder?.poNumber === currPo.poNumber
        )
        const obj = {
          ...curr,
          poNumber: currPo.poNumber,
          estimateList,
        }
        arr.push(obj)
      }
    }

    return {
      data: arr,
    }
  }

  static async getPOCustomerList(payload = {}) {
    const params = adjustDefaultParams(payload)
    let query = `
    query {
      allPoCustomerImpl {
        poNumber
        slsQuotNumber
        poDate
        customerName
        totalAmount
        reductionHistory
        month
      }
    }`
    query = getQueryForAllAndCount(query, params)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)
    return getDataAndCountFromResponse(response)
  }
}
