import { axios, ENDPOINT } from "./constants"
import {
  adjustDefaultParams,
  getDataAndCountFromResponse,
  getQueryForAllAndCount,
  handleSilentApiError,
  newDictToStringParam,
} from "./utils"

const DELIVERY_NOTE_QUERY = `
deliveryNote {
  poDate
  idDeliveryNote
  poNumber
  preparedById
  notes
  dnNumber
  purchaseOrder {
    idSalesQuotation
    status
    poNumber
    customerId
    estimateList {
      description
      quantity
      idSalesEstimate
      uom {
        name
      }
      relatedWorkOrderEstimate {
        edges{
          node {
            idWorkOrderEstimate
            workOrder {
              woNumber
            }
            mouldCode
            status
          }
        }
      }
    }
  }
  productDetails{
    idDnDetail
    description
    quantity
    unit
    remarks
    salesEstimateId
    mouldCode
    salesEstimate {
      handling
      reductionHistory
      quantity
      estQuot {
        processCostHandling
        materialCostHandling
        additionalCostHandling
        processCosts {
          id
          time
          processId
          process {
            cost
          }
        }
        materialCosts {
          quantity
          material {
            unitPrice
            dimension
          }
        }
        additionalCosts {
          quantity
          process
          cost
          handling
        }
      }
    }
  }
}`

const RATE_QUERY = `
rateIn{
  idCurr
  code
  description
}
rateInId`

const INVOICE_QUERY = `
idInvoice
paidDate
paymentStatus
invoiceNumber
date
invoiceType
enableRevise
remarks
paymentTerms
address
phone
attn
currencyRate
paymentPercentage
payment
discount
vat
source
notes
rateInId
currency {
  description
  code
}
status
mergedDetails {
  invoice {
    paymentPercentage
  }
  idInvoiceDetailMerge
  mouldCode
  description
  poNumber
  unit
  quantity
  details {
    paymentPercentage
    unitPrice
    unit
    quantity
    description
    dnDetailId
    slsEstimateId
    remark
    idInvoiceDetail
    invoiceDetailMergeId
    
    productDetail {
      mouldCode
      salesEstimateId
      unitPrice
      description
      mouldCode
      quantity
      unit
      remarks
      deliveryNote {
        dnNumber
      }
      salesEstimate {
        relatedWorkOrderEstimate {
          edges {
            node {
              workOrder {
                projectName
              }
            }
          }
        }
        slsPurchaseOrder {
          poNumber
          poDate
        }
        slsQuot{
          poNumber
          slsQuotDate
          slsQuotNumber
          poDate
        }
      }
    }
  }
}
customer {
  name
  idCust
  custCode
}
authorizedSignature {
  name
  position
}
deliveryNoteId
authorizedSignatureId
currencyId
customerId
`

const PRODUCT_DETAIL_QUERY = `
productDescriptions {
  unitPrice
  unit
  quantity
  description
  dnDetailId
  slsEstimateId
  remark
  sequence
  idInvoiceDetail
  invoiceDetailMergeId
  paymentPercentage
  additionalCosts {
    poNumber
    description
    unit
    unitPrice
    remark
    quantity
  }
  slsEstimate {
    slsQuotId
    idSalesEstimate
    slsQuot {
      slsQuotNumber
      poNumber
      poDate
    }
    details {
      slsPurchaseOrder {
        poNumber
        poDate
      }
      uom {
        idUom
        name
      }
      description
      unitPrice
      quantity 
    }
    slsQuotId
    slsPurchaseOrder {
      poNumber
      poDate
    }
    estQuot {
      processCostHandling
      materialCostHandling
      additionalCostHandling
      processCosts {
        id
        time
        cost
        processId
        process {
          cost
        }
      }
      materialCosts {
        materialId
        dimension
        quantity      
        sequence
        unitPrice
        material {
          materialCode
          unitPrice
          dimension
          idMaterial
          materialType {
            density
            name
          }
        }
      }
      additionalCosts {
        quantity
        process
        cost
        handling
      }
    }
    relatedWorkOrderEstimate {
      edges {
        node {
          mouldCode
          workOrder {
            projectName
          }
        }
      }
    }
  }
  productDetail {
    mouldCode
    salesEstimateId
    deliveryNoteId
    unitPrice
    description
    mouldCode
    quantity
    unit
    remarks
    deliveryNote {
      dnNumber
    }
    salesEstimate {
      details {
        slsPurchaseOrder {
          poNumber
          poDate
        }
        uom {
          idUom
          name
        }
        description
        unitPrice
        quantity 
      }
      relatedWorkOrderEstimate {
        edges {
          node {
            workOrder {
              projectName
            }
          }
        }
      }
      slsPurchaseOrder {
        poNumber
        poDate
      }
      slsQuot{
        poNumber
        slsQuotDate
        slsQuotNumber
        poDate
      }
    }
  }
}
`
export default class ApiInvoice {
  static async getItem(id) {
    const query = `
    query {
      getInvoiceImpl (id: ${id}) {
        ${INVOICE_QUERY}
        ${DELIVERY_NOTE_QUERY}
        ${PRODUCT_DETAIL_QUERY}
        ${RATE_QUERY}
      }
    }`

    const response = await axios.post(ENDPOINT.QUERY, { query })
    handleSilentApiError(response)

    return { data: response.data.data.getInvoiceImpl }
  }

  static async get(inpParams = {}) {
    const params = adjustDefaultParams(inpParams)

    let query = `
    query {
      allInvoiceImpl {
        ${INVOICE_QUERY}
        paymentStatus
        paidDate
        deliveryNote {
          dnNumber
        }
        productDescriptions {
          description
          productDetail {
            mouldCode
            deliveryNote {
              dnNumber
            }
            salesEstimate{
              slsPurchaseOrder{
                poNumber
              }
            }
          }
          slsEstimate{
            slsPurchaseOrder{
              poNumber
            }
            relatedWorkOrderEstimate {
              edges {
                node {
                  mouldCode
                }
              }
            }
          }
        }
      }
    }`
    query = getQueryForAllAndCount(query, params)
    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)

    return getDataAndCountFromResponse(response)
  }

  static async update(id, payload) {
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation ($id: ID!, $invoice: InvoiceInput!){
        updateInvoiceImpl(idInvoice: $id, invoice: $invoice) {
          invoiceImpl {
            ${INVOICE_QUERY}
            ${DELIVERY_NOTE_QUERY}
            ${PRODUCT_DETAIL_QUERY}
          }
        }
      }`,
      variables: { id, invoice: payload },
    })
    handleSilentApiError(response)
    return {
      data: response.data.data.updateInvoiceImpl.invoiceImpl,
    }
  }

  static async mergeInvoiceDetail(payload) {
    const params = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
      mutation {
        saveInvoiceMergeImpl (${params}) {
          invoiceImpl {
            idInvoice
          }
        }
      }`,
    })
    handleSilentApiError(response)
    return {
      data: response.data.data.saveInvoiceMergeImpl.invoiceImpl,
    }
  }

  static async deleteMergeInvoiceDetail(id) {
    const response = await axios.post(ENDPOINT.DELETE, {
      query: `
      mutation {
        deleteInvoiceMergeImpl (id: ${id}) {
          invoiceImpl {
            idInvoice
          }
        }
      }`,
    })
    handleSilentApiError(response)
    return {
      data: response.data.data.deleteInvoiceMergeImpl.invoiceImpl,
    }
  }

  static async updateMergeInvoiceDetail(payload) {
    const params = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        updateInvoiceMergeImpl (${params}) {
          invoiceImpl {
            idInvoice
          }
        }
      }`,
    })
    handleSilentApiError(response)
    return {
      data: response.data.data.updateInvoiceMergeImpl.invoiceImpl,
    }
  }

  static async create(payload) {
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
        mutation ($invoice: InvoiceInput!){
            saveInvoiceImpl(invoice: $invoice) {
                invoiceImpl {
                    idInvoice
                }
            }
        }`,
      variables: { invoice: payload },
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.saveInvoiceImpl.invoiceImpl,
    }
  }

  static async updateStatus(id, payload) {
    const payloadAsParam = newDictToStringParam({
      status: payload,
      idInvoice: parseInt(id, 10),
    })
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        updateStatusInvoiceImpl (${payloadAsParam}) {
          invoiceImpl{
            status
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.updateStatusInvoiceImpl.updateStatusInvoice,
    }
  }

  static async updatePaymentStatus(id, payload) {
    const payloadAsParam = newDictToStringParam({
      ...payload,
      idInvoice: parseInt(id, 10),
    })
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        updatePaymentStatusInvoiceImpl (${payloadAsParam}) {
          invoiceImpl{
            status
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.updatePaymentStatusInvoiceImpl.invoiceImpl,
    }
  }

  static async getSalesSummary(payload = {}) {
    const params = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        getSalesSummaryImpl(${params}){
          total
          currencyCode
        }
      }`,
    })

    handleSilentApiError(response)
    return {
      data: response.data.data.getSalesSummaryImpl,
    }
  }

  static async getInvoiceSales(payload = {}) {
    const params = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allInvoiceSalesImpl(${params}){
          description
          idInvoiceDetail
          invoice {
            invoiceNumber
            currencyRate
            date
            paymentPercentage
            currency {
              code
            }
            rateIn {
              code
            }
            discount
            vat
          }
          slsEstimate {
            relatedWorkOrderEstimate {
              edges {
                node {
                  mouldCode
                }
              }
            }
          }
          productDetail {
            mouldCode
          }
          unitPrice
        }
      }`,
    })

    handleSilentApiError(response)
    return {
      data: response.data.data.allInvoiceSalesImpl,
    }
  }

  static async updateEnableReviseSQ(params) {
    const payload = newDictToStringParam(params)

    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        updateInvoiceEnableReviseStatusImpl(${payload}){
          invoiceImpl {
            enableRevise
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.updateInvoiceEnableReviseStatusImpl.invoiceImpl,
    }
  }

  static async getInvoiceYear() {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allInvoiceYearImpl
      }`,
    })

    handleSilentApiError(response)

    return {
      data: response.data.data.allInvoiceYearImpl,
    }
  }
}
