/* eslint-disable react/prop-types */
import { Grid, TextField } from "@material-ui/core"
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react"
import { transformProperty } from "utils/objects"
import InvoiceFormContext from "./InvoiceFormContex"
import { currencyOperation, getDefaultSettings } from "./utils"

const TotalCostSection = forwardRef(({ initialValue }, ref) => {
  const {
    productDescriptions,
    paymentPercentage,
    currencyRate,
    rateIn,
  } = useContext(InvoiceFormContext)

  const subTotal = useMemo(() => {
    return productDescriptions.reduce((a, b) => {
      let percentage = b.paymentPercentage
      if (!percentage) {
        percentage = paymentPercentage ?? 100
      }
      return (
        a +
        currencyOperation(+b.unitPrice, rateIn, currencyRate) *
          (0 + percentage / 100)
      )
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentPercentage, productDescriptions, currencyRate])

  const [discount, setDiscount] = useState(initialValue?.discount ?? 0)
  const [vat, setVat] = useState(initialValue?.vat ?? 0)

  const dpp = useMemo(() => {
    return (11 / vat) * subTotal
  }, [vat, subTotal])

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      const data = { discount, vat }
      transformProperty(data, ["discount", "vat"], parseInt)
      if (!data.discount || data.discount <= 0) delete data.discount
      if (!data.vat || data.vat <= 0) delete data.vat
      return data
    },
  }))

  const totalCost = useMemo(() => {
    return (subTotal - discount) * (1 + vat / 100)
  }, [discount, subTotal, vat])
  return (
    <Grid container>
      <Grid item md={3} style={{ marginRight: 0, marginLeft: "auto" }}>
        <TextField
          label="Sub Total"
          fullWidth
          margin="dense"
          disabled
          value={new Intl.NumberFormat().format(Math.ceil(subTotal))}
        />
        <TextField
          {...getDefaultSettings("discount", "Discount")}
          value={discount}
          type="number"
          onChange={(e) => setDiscount(e.target.value)}
        />

        {+vat === 12 ? (
          <TextField
            label="DPP"
            fullWidth
            margin="dense"
            disabled
            value={new Intl.NumberFormat().format(Math.floor(dpp))}
          />
        ) : null}

        <TextField
          {...getDefaultSettings("vat", "Vat (%)")}
          value={vat}
          type="number"
          onChange={(e) => setVat(e.target.value)}
        />

        <TextField
          disabled
          {...getDefaultSettings("total", "Total")}
          value={new Intl.NumberFormat().format(Math.ceil(totalCost))}
        />
      </Grid>
    </Grid>
  )
})

export default TotalCostSection
